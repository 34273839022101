<template>
  <b-row>
    <b-col md="3">
      <profil-kelompok :detailKelompok=detailKelompok></profil-kelompok>
    </b-col>

    <b-col md="9">
      <b-card>
        <tabelData :items='items_kordinat' @editsData='editsData' :id_kelompok=id_kelompok></tabelData>
      </b-card>
    </b-col>
    <formAdd :id_kelompok=id_kelompok @simpan_kordinat=simpan_kordinat></formAdd>
    <formEdit :inputData=inputData @update_kordinat=update_kordinat></formEdit>
  </b-row>
</template>

<script>
  import formEdit from './formEdit.vue';
  import profilKelompok from './profilKelompok.vue';
  import axios from '@/config/Axios'
  import Base from '@/config/Mixins_base';
  import tabelData from './tabel.vue'
  import formAdd from './formAdd.vue'
  import Ripple from 'vue-ripple-directive'
  import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BModal,
    VBModal,
  } from 'bootstrap-vue'
  import validasi from '@/store/dinas/validasi';

  export default {
    components: {
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      tabelData,
      BCard,
      BCardText,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      formAdd,
      profilKelompok,
      BModal,
      VBModal,
      formEdit

    },
    data() {
      return {
        items_kordinat: [],
        dataEdit: {},
        id_kelompok: this.$route.params.id_kelompok,
        detailKelompok: {},
        inputData: {}
      }
    },
    computed: {},
    mounted() {
      this.cek_token();
      this.get_edit();
      this.laod_data()
    },
    mixins: [Base],
    methods: {
      async laod_data() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/kordinat/load_data',
            data: {
              id_kelompok: self.id_kelompok
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items_kordinat = response.data.result;
            self.dataEdit = {}

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_edit() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/kelompok/get_edit',
            data: {
              id_kelompok: self.id_kelompok
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.detailKelompok = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async load_edit_kordinat(value) {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/kordinat/get_edit',
            data: {
              id_kordinat: value.id_kordinat
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.inputData = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      editsData(value) {
        this.load_edit_kordinat(value);
      },
      async simpan_kordinat(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/kordinat/insert',
            data: {
              id_kelompok: self.id_kelompok,
              nama_kordinat: value.nama_kordinat,
              keterangan: value.keterangan,
              lat: value.lat,
              log: value.log,
              tgl_mulai: value.tgl_mulai,
              tgl_selesai: value.tgl_selesai,
              max_radius: value.max_radius,
              jenis_radius: value.jenis_radius,
              status: value.status
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "", response.data.message);
            self.laod_data();
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async update_kordinat(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/kordinat/update',
            data: {
              id_kordinat: value.id_kordinat,
              nama_kordinat: value.nama_kordinat,
              keterangan: value.keterangan,
              lat: value.lat,
              log: value.log,
              tgl_mulai: value.tgl_mulai,
              tgl_selesai: value.tgl_selesai,
              max_radius: value.max_radius,
              jenis_radius: value.jenis_radius,
              status: value.status
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "", response.data.message);
            self.laod_data();
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },

  }
</script>