<template>
    <b-row>
        <b-col md="5" sm="6" class="my-1">
            <b-row>
                <b-col md="4">
                    <b-form-group class="mb-0">
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                            class="w-15" style="width: 100px;" />
                    </b-form-group>
                </b-col>
            </b-row>

        </b-col>

        <b-col md="5" class="my-1">
            <b-form-group label=" " label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                style="width: 100%" class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>
        <b-col md="2">
            <b-button size="sm" class="mt-1" variant="primary"  v-b-modal.TambahKordinat >
                <i class="fa fa-plus mr-1  "></i> Koordinat
            </b-button>

        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(status)="data">
                    <b-row>
                        <b-col md="1">
                            <a :href="'https://www.google.com/maps/search/?api=1&query='+  data.item.lat +',' + data.item.log"
                                target="_blank">
                                <b-avatar size="40px" rounded="" variant="light-info" v-if="data.item.status == '1'">
                                    <feather-icon icon="MapPinIcon" size="30" />

                                </b-avatar>
                            </a>
                            <a :href="'https://www.google.com/maps/search/?api=1&query='+  data.item.lat +',' + data.item.log"
                                target="_blank">
                                <b-avatar size="40px" rounded="" variant="light-danger" v-if="data.item.status != '1'">
                                    <feather-icon icon="MapPinIcon" size="30" />

                                </b-avatar>
                            </a>
                        </b-col>
                        <b-col md="9">
                            <b>Nama Lokasi {{ data.item.nama_kordinat}} </b>
                            <p>Tanggal Aktif <b> {{ data.item.tgl_mulai}}</b> sampai dengan
                                <b>{{ data.item.tgl_selesai}}</b><br>
                                <b>Koordinat</b> {{ data.item.lat}} , {{ data.item.log}}<br>Maximum Radius Presensi
                                <b>{{ data.item.max_radius}} </b>{{ data.item.jenis_radius}} </p>
                            <small>Keterangan : <br> {{ data.item.keterangan}}</small>

                        </b-col>
                        <b-col md="2">
                            <b-button size="sm" variant="outline-warning" @click="get_edit(data.item)" v-b-modal.editKordinat>
                                <i class="fa fa-pencil"></i>  <br>Edit Koordinat
                            </b-button>
                        </b-col>
                    </b-row>
                </template>

            </b-table>
        </b-col>
        <b-col cols="6">Jumlah Koordinat {{ items.length }}</b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import axios from '@/config/Axios'
    import Base from '@/config/Mixins_base';
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton
    } from 'bootstrap-vue'

    export default {
        mixins: [Base],
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        data() {
            return {
                perPage: 2,
                pageOptions: [2, 10, 100, 1000],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                        key: 'status',
                        label: 'Daftar Koordinat Kelompok',
                    },


                ],


            }
        },
        emits: ["editsData"],
        props: {
            items: {},
            id_kelompok: ''
        },
        computed: {
            sortOptions() {
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            this.totalRows = this.items.length;
        },
        methods: {
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },

            async act_status(data) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/master/bidang/status',
                        data: {
                            id_bidang: data.id_bidang
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.laod_data()
                        self.notification('info', "", response.data.message);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },



            get_edit(value) {
                this.$emit("editsData", value);
            }
        },
    }
</script>