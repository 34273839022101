var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"TambahKordinat","title":"Tambah Koordinat Presensi","size":"lg","hide-footer":"","no-close-on-backdrop":"","content-class":"shadow","ok-title":"Keluar"}},[_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Nama Koordinat")]),_c('validation-provider',{attrs:{"name":"nama_titik_kordinat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nama Titik Koordinat"},model:{value:(_vm.inputData.nama_kordinat),callback:function ($$v) {_vm.$set(_vm.inputData, "nama_kordinat", $$v)},expression:"inputData.nama_kordinat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Koordinat Longitude ")]),_c('validation-provider',{attrs:{"name":"Jam_masuk","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Latitude"},model:{value:(_vm.inputData.lat),callback:function ($$v) {_vm.$set(_vm.inputData, "lat", $$v)},expression:"inputData.lat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Koordinat Longitude ")]),_c('validation-provider',{attrs:{"name":"jam_pulang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Longitude"},model:{value:(_vm.inputData.log),callback:function ($$v) {_vm.$set(_vm.inputData, "log", $$v)},expression:"inputData.log"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Tanggal Mulai ")]),_c('validation-provider',{attrs:{"name":"tgl_mulai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"tgl_mulai","state":errors.length > 0 ? false:null},model:{value:(_vm.inputData.tgl_mulai),callback:function ($$v) {_vm.$set(_vm.inputData, "tgl_mulai", $$v)},expression:"inputData.tgl_mulai"}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Tanggal Selesai")]),_c('validation-provider',{attrs:{"name":"tgl_selesai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"tgl_selesai","state":errors.length > 0 ? false:null},model:{value:(_vm.inputData.tgl_selesai),callback:function ($$v) {_vm.$set(_vm.inputData, "tgl_selesai", $$v)},expression:"inputData.tgl_selesai"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Maximum Radius Presensi ")]),_c('validation-provider',{attrs:{"name":"max_radius","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null,"placeholder":"max_radius"},model:{value:(_vm.inputData.max_radius),callback:function ($$v) {_vm.$set(_vm.inputData, "max_radius", $$v)},expression:"inputData.max_radius"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Jenis Radius")]),_c('validation-provider',{attrs:{"name":"tgl_selesai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.jenis_radius},model:{value:(_vm.inputData.jenis_radius),callback:function ($$v) {_vm.$set(_vm.inputData, "jenis_radius", $$v)},expression:"inputData.jenis_radius"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Status")]),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('b-form-radio',{attrs:{"name":"some-radios","value":"1","checked":""},model:{value:(_vm.inputData.status),callback:function ($$v) {_vm.$set(_vm.inputData, "status", $$v)},expression:"inputData.status"}},[_vm._v(" Aktif ")])],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('b-form-radio',{attrs:{"name":"some-radios","value":"0"},model:{value:(_vm.inputData.status),callback:function ($$v) {_vm.$set(_vm.inputData, "status", $$v)},expression:"inputData.status"}},[_vm._v(" Non Aktif ")])],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Keterangan")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Keterangan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"id":"textarea-default","placeholder":"Textarea","rows":"3"},model:{value:(_vm.inputData.keterangan),callback:function ($$v) {_vm.$set(_vm.inputData, "keterangan", $$v)},expression:"inputData.keterangan"}})]}}])})],1)],1)],1),_c('hr'),_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.SimpanKordinat()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"15"}}),_vm._v(" Simpan ")],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-warning"},on:{"click":function($event){return _vm.Batalkan()}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"15"}}),_vm._v(" Batalkan ")],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }