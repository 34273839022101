<template>
    <div>
        <!-- basic modal -->
        <b-modal id="TambahKordinat" title="Tambah Koordinat Presensi" size="lg" hide-footer no-close-on-backdrop
            content-class="shadow" ok-title="Keluar">
            <div> 
                <validation-observer ref="simpleRules">
                    <b-form>
                        <b-row>
                            <b-col md="12">
                                <b-form-group>
                                    <label>Nama Koordinat</label>
                                    <validation-provider #default="{ errors }" name="nama_titik_kordinat" rules="required">
                                        <b-form-input v-model="inputData.nama_kordinat"
                                            :state="errors.length > 0 ? false:null" placeholder="Nama Titik Koordinat" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Koordinat Longitude </label>
                                    <validation-provider #default="{ errors }" name="Jam_masuk" rules="required">
                                        <b-form-input v-model="inputData.lat" :state="errors.length > 0 ? false:null"
                                            placeholder="Latitude" />
                                        <small class="text-danger">{{ errors[0] }}</small></validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Koordinat Longitude </label>
                                    <validation-provider #default="{ errors }" name="jam_pulang" rules="required">
                                        <b-form-input v-model="inputData.log" :state="errors.length > 0 ? false:null"
                                            placeholder="Longitude" />
                                        <small class="text-danger">{{ errors[0] }}</small></validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Tanggal Mulai </label>
                                    <validation-provider #default="{ errors }" name="tgl_mulai" rules="required">
                                        <b-form-datepicker id="tgl_mulai" :state="errors.length > 0 ? false:null" 
                                            v-model="inputData.tgl_mulai" class="mb-1" /> <small
                                            class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Tanggal Selesai</label>
                                    <validation-provider #default="{ errors }" name="tgl_selesai" rules="required">
                                        <b-form-datepicker id="tgl_selesai" :state="errors.length > 0 ? false:null" 
                                            v-model="inputData.tgl_selesai" class="mb-1" /> 
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>


                            <b-col cols="6">
                                <b-form-group>
                                    <label>Maximum Radius Presensi </label>
                                    <validation-provider #default="{ errors }" name="max_radius" rules="required">
                                        <b-form-input type="number" v-model="inputData.max_radius" :state="errors.length > 0 ? false:null"
                                            placeholder="max_radius" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Jenis Radius</label>
                                    <validation-provider #default="{ errors }" name="tgl_selesai" rules="required">
                                        <b-form-select v-model="inputData.jenis_radius" :state="errors.length > 0 ? false:null"
                                            :options="jenis_radius"   /> 
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12">
                                <label>Status</label>
                                <b-row>
                                    <b-col cols="3">
                                        <b-form-group>
                                            <b-form-radio v-model="inputData.status" name="some-radios" value="1" checked="">
                                                Aktif
                                            </b-form-radio>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-form-group>
                                            <b-form-radio v-model="inputData.status" name="some-radios" value="0">
                                                Non Aktif
                                            </b-form-radio>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>


                            <b-col cols="12">
                                <label>Keterangan</label>
                                <b-form-group>
                                    <validation-provider #default="{ errors }" name="Keterangan">
                                        <b-form-textarea :state="errors.length > 0 ? false:null"
                                            v-model="inputData.keterangan"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" id="textarea-default"
                                            placeholder="Textarea" rows="3" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mb-5">
                            <b-col md="6">
                                <b-button   @click="SimpanKordinat()"
                                    variant="outline-primary">
                                    <feather-icon icon="SaveIcon" size="15" /> Simpan
                                </b-button>
                                <b-button   class="ml-1" @click="Batalkan()"
                                    variant="outline-warning">
                                    <feather-icon icon="XCircleIcon" size="15" /> Batalkan
                                </b-button>
                            </b-col>

                        </b-row>
                    </b-form>
                </validation-observer>

            </div>
        </b-modal>
    </div>
</template>

<script>
    import axios from '@/config/Axios';
    import Base from '@/config/Mixins_base';
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required
    } from '@validations'

    import vSelect from 'vue-select'


    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BCardText,
        BAvatar,
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTime,
        BFormDatepicker,
        BFormRadio,
        BFormSelect
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import flatPickr from 'vue-flatpickr-component'

    export default {
        components: {
            BButton,
            BModal,
            BAlert,
            BCardText,
            BAvatar,
            vSelect,
            ValidationProvider,
            ValidationObserver,
            BForm,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BTime,
            flatPickr,
            BFormDatepicker,
            BFormRadio,
            BFormSelect
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: {
            detailKelompok: {},
            bidang: {},
            id_kelompok: {}
        },
        mixins: [Base],
        emits: ["simpan_kordinat"],
        data() {
            return {
                data : {},
                required,
                inputData: {},
                timePicker: null,
                jenis_radius: [{
                        value: 'meter',
                        text: 'Meter'
                    },
                    {
                        value: 'kilo_meter',
                        text: 'Kilometer'
                    },
                ]
            }
        },
        methods: {
            onContext(ctx) {
                this.context = ctx
            },
            SimpanKordinat() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$root.$emit('bv::hide::modal', 'TambahKordinat')
                        this.$emit("simpan_kordinat", this.inputData)
                        this.inputData = {}
                    } else {

                    }
                })
            },
            Batalkan() {
                this.$root.$emit('bv::hide::modal', 'TambahKordinat')
            }
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>